import {
  format,
  formatDistanceToNowStrict,
  isSameDay,
  isSameWeek,
  isSameYear,
  isThisMonth,
  isThisYear,
  isToday,
  isValid,
} from 'date-fns'

export function formatDate(date?: string | Date | null, options?: { relative?: boolean, hourSeparator?: string }) {
  if (!date) {
    return ''
  }

  const {
    relative = false,
    hourSeparator = 'à',
  } = options || {}

  let _date: Date
  if (typeof date === 'string') {
    _date = new Date(date)
    if (!isValid(_date)) {
      return ''
    }
  }
  else {
    _date = date
  }

  if (isToday(_date)) {
    if (relative)
      return formatDistanceToNowStrict(_date, { addSuffix: true })

    return format(_date, `H'h'mm`)
  }

  if (isThisYear(_date)) {
    return format(_date, `d MMM '${hourSeparator}' H'h'mm`)
  }

  return format(_date, `d MMM yyyy '${hourSeparator}' H'h'mm`)
}

export function formatDateEndFrom(
  date?: string | Date | null,
  from?: string | Date | null,
) {
  if (!date) {
    return ''
  }
  if (!from) {
    return formatDate(date)
  }

  let _date: Date
  if (typeof date === 'string') {
    _date = new Date(date)
    if (!isValid(_date)) {
      return ''
    }
  }
  else {
    _date = date
  }

  const _from = typeof from === 'string' ? new Date(from) : from

  if (isSameDay(_date, _from)) {
    return format(_date, 'H\'h\'mm')
  }
  if (isSameWeek(_date, _from)) {
    return format(_date, 'EEE d H\'h\'mm')
  }
  if (isSameYear(_date, _from)) {
    return format(_date, 'd MMM H\'h\'mm')
  }

  return format(_date, 'd MMM yyyy H\'h\'mm')
}

export function formatDateDay(date?: string | Date | null) {
  if (!date) {
    return ''
  }

  let _date: Date
  if (typeof date === 'string') {
    _date = new Date(date)
    if (!isValid(_date)) {
      return ''
    }
  }
  else {
    _date = date
  }

  if (isThisMonth(_date)) {
    return format(_date, 'EEEE d')
  }

  if (isThisYear(_date)) {
    return format(_date, 'EEEE d MMMM')
  }

  return format(_date, 'd MMMM yyyy')
}

export function formatDateIso(date?: Date | string | null) {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    date = new Date(date)
  }
  if (!(date instanceof Date)) {
    const msg = `formatDateIso: Invalid date provided. Expected Date, got ${typeof date} with value ${date}`
    console.error(msg)
    return msg
  }

  return date.toLocaleDateString('fr-FR', {
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function formatMinutes(minutes?: number | null, noValue = '0 min') {
  // split minutes into hours and minutes
  if (!minutes)
    return noValue
  if (Number.isNaN(minutes))
    return '-'

  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const minutesLeft = Math.round(minutes % 60)

  if (hours < 1) {
    return `${minutesLeft} min`
  }

  if (hours < 2) {
    if (!minutesLeft)
      return `${hours} h`
    return `${hours} h ${minutesLeft} min`
  }

  if (hours <= 72) {
    return `${hours} h`
  }

  return `${days} j`
}
